import { useState } from "react";
import { useAuth } from "../store/auth";
import { Link, NavLink, useNavigate } from "react-router-dom";

const Calendar = () => {
  const calendardata = [
    {
      label: "Conference Room",
      value: "conference-room",
      des: (
        <iframe
          src="https://calendar.google.com/calendar/embed?src=d623ba6cded4ad25c01be956913ade73d57decc21fd6d07b16c3dff36406cc5d%40group.calendar.google.com&ctz=Asia%2FKolkata"
          className=" w-[80%] m-auto h-[600px] bg-orange-100  overflow-hidden relative  rounded-2xl p-10 text-xl md:text-4xl font-bold text-white bg-gradient-to-br from-orange-100 to-orange-100"
          frameBorder="0"
          scrolling="no"
          title="Conference Room Calendar"
        ></iframe>
      ),
    },
    {
      label: "Bhaktivedanta Hall",
      value: "bhaktivedanta-hall",
      des: (
        <iframe
          src="https://calendar.google.com/calendar/embed?src=77a6194605e843ee7cf85475c9450f3aec8d787615a36dceb67f0ee94168bd49%40group.calendar.google.com&ctz=Asia%2FKolkata"
          className=" w-[80%] m-auto h-[600px]  overflow-hidden relative  rounded-2xl p-10 text-xl md:text-4xl font-bold text-white bg-gradient-to-br from-orange-100 to-orange-100"
          frameBorder="0"
          scrolling="no"
          title="Bhaktivedanta Hall Calendar"
        ></iframe>
      ),
    },
    {
      label: "Bhaktisiddhanta Hall",
      value: "bhaktisiddhanta-hall",
      des: (
        <iframe
          src="https://calendar.google.com/calendar/embed?src=edaa6e1034bf94afd350955644ac544222cd77060da725bcf0219e8229c1bb7c%40group.calendar.google.com&ctz=Asia%2FKolkata"
          className=" w-[80%] m-auto h-[600px]  overflow-hidden relative  rounded-2xl p-10 text-xl md:text-4xl font-bold text-white bg-gradient-to-br from-orange-100 to-orange-100"
          frameBorder="0"
          scrolling="no"
          title="Bhaktisiddhanta Hall Calendar bg-orange-100"
        ></iframe>
      ),
    },
    {
      label: "Gopalji Hall",
      value: "gopalji-hall",
      des: (
        <iframe
          src="https://calendar.google.com/calendar/embed?src=391be59d0466d0ccf3606d3086a9b2368621a5b8ccae847f2e151e4da058a134%40group.calendar.google.com&ctz=Asia%2FKolkata"
          className=" w-[80%] m-auto h-[600px]  overflow-hidden relative  rounded-2xl p-10 text-xl md:text-4xl font-bold text-white bg-gradient-to-br from-orange-100 to-orange-100"
          frameBorder="0"
          scrolling="no"
          title="Gopalji Hall Calendar"
        ></iframe>
      ),
    },
    {
      label: "BACE Class Rooms (Nitai room included)",
      value: "bace-class-rooms",
      des: (
        <iframe
          src="https://calendar.google.com/calendar/embed?src=da821b950210929e172a782be2010657bb0bfb48784777f6440e914c0b247282%40group.calendar.google.com&ctz=Asia%2FKolkata"
          className=" w-[80%] m-auto h-[600px]  overflow-hidden relative  rounded-2xl p-10 text-xl md:text-4xl font-bold text-white bg-gradient-to-br from-orange-100 to-orange-100"
          frameBorder="0"
          scrolling="no"
          title="BACE Class Rooms Calendar"
        ></iframe>
      ),
    },
    {
      label: "Laxmi Narayan Hall",
      value: "laxmi-narayan-hall",
      des: (
        <iframe
          src="https://calendar.google.com/calendar/embed?src=5340a928e6268fb56f9aa0399cd7ee720f2a7f9e0630528fcfe398c5cffa73a1%40group.calendar.google.com&ctz=Asia%2FKolkata"
          className=" w-[80%] m-auto h-[600px]  overflow-hidden relative  rounded-2xl p-10 text-xl md:text-4xl font-bold text-white bg-gradient-to-br from-orange-100 to-orange-100"
          frameBorder="0"
          scrolling="no"
          title="Laxmi Narayan Hall Calendar"
        ></iframe>
      ),
    },
    {
      label: "Bhaktivinoda Hall ",
      value: "bhaktivinoda-hall",
      des: (
        <iframe
          src="https://calendar.google.com/calendar/embed?src=8aae3f6002bac136d8c605cd3905d2bba586155cfaa15ec0b4bcaf4fbb1dbc55%40group.calendar.google.com&ctz=Asia%2FKolkata"
          className=" w-[80%] m-auto h-[600px]  overflow-hidden relative  rounded-2xl p-10 text-xl md:text-4xl font-bold text-white bg-gradient-to-br from-orange-100 to-orange-100"
          frameBorder="0"
          scrolling="no"
          title="Bhaktivinoda Hall Calendar"
        ></iframe>
      ),
    },
  ];
  const halls = [
    {
      path: "/bookingform",
    },
  ];

  const [activeTab, setActiveTab] = useState(calendardata[0].value);

  const { isLoggedIn } = useAuth();
  const navigate = useNavigate();

  // const handleBookingClick = (path) => {
  //   {
  //     isLoggedIn ? navigate(path) : navigate();
  // //   }
  // };

  return (
    <div className="bg-orange-100 pt-[6rem] p-4 md:p-10 mt-4 md:mt-16">
      <div className="flex flex-col md:flex-row">
        {/* Sidebar with tabs */}
        <div className="w-full md:w-1/4 md:pt-16 md:pl-4 mb-4 md:mb-0">
          {calendardata.map((item) => (
            <button
              key={item.value}
              className={`w-full py-2 px-4 text-left focus:outline-none ${
                activeTab === item.value
                  ? "bg-orange-500 text-white"
                  : "bg-gray-200 text-gray-800"
              } hover:bg-orange-500 hover:text-white transition-colors duration-300 rounded-md mb-2`}
              onClick={() => setActiveTab(item.value)}
            >
              {item.label}
            </button>
          ))}
          <div className="text-center mb-4 mt-8">
            {isLoggedIn ? (
             <>
             <h1 className="mb-2">To Book ? click below</h1>
              <Link to="/bookingform">
                <button className="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-colors duration-300">
                  Request Booking Form
                </button>
              </Link> 
             </>
            ) : (
              <Link to="/login">
                <button className="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-colors duration-300">
                  Request Booking Form
                </button>
              </Link>
            )}
          </div>
        </div>

        {/* Calendar displays */}
        <div className="w-full md:w-3/4">
          {calendardata.map((item) => (
            <div
              key={item.value}
              className={`${
                activeTab === item.value ? "block" : "hidden"
              } mb-4`}
            >
              <div className="relative pb-[56.25%] h-0 overflow-hidden rounded-lg">
                <iframe
                  src={item.des.props.src}
                  className="absolute md:top-10 md:left-[8rem] md:w-[80%] md:h-full w-full h-full"
                  frameBorder="0"
                  scrolling="no"
                  title={item.label}
                ></iframe>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Calendar;
